import React, { useState, useRef, useEffect } from "react";
import { Grid, Paper, LinearProgress, Box } from "@mui/material";
import Item from "./Item";
import CorrectAnswerAnimation from "./CorrectAnswerAnimation";
import "./styles.css";

const generateGrid = (level) => {
  const gridSizes = [
    [3, 3],
    [3, 3],
    [4, 3],
    [4, 3],
    [4, 4],
  ];
  const pairs = [1, 1, 2, 2, 2, 3];
  const [rows, cols] = gridSizes[Math.min(level - 1, gridSizes.length - 1)];
  const totalItems = rows * cols;

  const items = [
    "🍎",
    "🍌",
    "🐵",
    "🐶",
    "🐷",
    "🍇",
    "🍉",
    "🍒",
    "🍑",
    "🍍",
    "🥑",
    "🥦",
    "🥕",
    "🥔",
    "🌽",
    "🥭",
    "🥥",
    "🍋",
    "🍊",
    "🍓",
    "🍈",
    "🍅",
    "🍆",
    "🌶️",
    "🫒",
    "🥒",
    "🍄",
    "🌰",
    "🥜",
    "🦀",
    "🦞",
    "🦐",
    "🐓",
    "🐣",
    "🐸",
    "🐯",
    "🦁",
    "🐍",
    "🐢",
    "🐙",
    "🦄",
    "🐳",
  ];

  let grid = [];
  let usedItems = new Set();
  let pairsCount = pairs[Math.min(level - 1, pairs.length - 1)];

  // Place pairs in the grid
  for (let i = 0; i < pairsCount; i++) {
    let pairItem;
    do {
      pairItem = items[Math.floor(Math.random() * items.length)];
    } while (usedItems.has(pairItem));

    usedItems.add(pairItem);

    // Place the pair in random positions
    for (let j = 0; j < 2; j++) {
      let position;
      do {
        position = Math.floor(Math.random() * totalItems);
      } while (grid[position] !== undefined);
      grid[position] = pairItem;
    }
  }

  // Fill the remaining slots with unique items that are not used yet
  let uniqueItemPool = items.filter((item) => !usedItems.has(item));
  for (let i = 0; i < totalItems; i++) {
    if (grid[i] === undefined) {
      let uniqueItem;
      do {
        uniqueItem =
          uniqueItemPool[Math.floor(Math.random() * uniqueItemPool.length)];
      } while (usedItems.has(uniqueItem));

      usedItems.add(uniqueItem);
      grid[i] = uniqueItem;
    }
  }

  return { grid, rows, cols, pairsCount };
};
const GameBoard = ({ level, onCorrectAnswer, onWrongAnswer, onGameOver }) => {
  const [gridData, setGridData] = useState({
    grid: [],
    rows: 0,
    cols: 0,
    pairsCount: 0,
  });
  const [showAnimation, setShowAnimation] = useState(false);
  const [clickedItems, setClickedItems] = useState([]);
  const [clickedPairs, setClickedPairs] = useState([]);
  const [pairsLeft, setPairsLeft] = useState(0);
  const [highlight, setHighlight] = useState(null);
  const [timeLeft, setTimeLeft] = useState(20);
  const timerRef = useRef(null);

  useEffect(() => {
    const { grid, rows, cols, pairsCount } = generateGrid(level);
    setGridData({ grid, rows, cols, pairsCount });
    setPairsLeft(pairsCount);
    setClickedItems([]);
    setClickedPairs([]);
    setHighlight(null);
    setTimeLeft(20);
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 0) {
          clearInterval(timerRef.current);
          onGameOver();
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [level]);

  const getTimerColor = () => {
    if (timeLeft > 10) return "success";
    if (timeLeft > 5) return "warning";
    return "error";
  };

  const handleItemClick = (index) => {
    const item = gridData.grid[index];
    if (clickedItems.includes(index)) {
      return;
    }

    const count = gridData.grid.filter((i) => i === item).length;

    if (count === 2) {
      setHighlight("correct");
      if (!clickedPairs.includes(item)) {
        setPairsLeft(pairsLeft - 1);

        if (pairsLeft === 1) {
          setShowAnimation(true);
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          setTimeout(() => {
            setShowAnimation(false);
            onCorrectAnswer();
          }, 1000);
        }
      }
    } else {
      setHighlight("incorrect");
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setTimeout(() => {
        onWrongAnswer();
      }, 500);
    }
    setClickedItems([...clickedItems, index]);
    setClickedPairs([...clickedPairs, item]);
  };

  return (
    <>
      <div className="info">
        <p>Pairs left: {pairsLeft}</p>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={(timeLeft / 20) * 100}
            color={getTimerColor()}
            sx={{ height: 10, borderRadius: 5 }}
          />
         
        </Box>
      </div>
      <Grid container spacing={1} justifyContent="center">
        {gridData.grid.map((item, index) => (
          <Grid item xs={Math.floor(12 / gridData.cols)} key={index}>
            <Paper
              className={`grid-item ${
                highlight === "correct" && clickedItems.includes(index)
                  ? "correct"
                  : ""
              } ${
                highlight === "incorrect" && clickedItems.includes(index)
                  ? "incorrect"
                  : ""
              }`}
              onClick={() => handleItemClick(index)}
            >
              <Item emoji={item} />
            </Paper>
          </Grid>
        ))}
      </Grid>
      <CorrectAnswerAnimation
        isStopped={!showAnimation}
        showAnimation={showAnimation}
      />
    </>
  );
};

export default GameBoard;
