import React from 'react';
import Lottie from 'react-lottie';
import animationData from './correctAnswerAnimation2.json'; // Replace with the path to your animation JSON

const CorrectAnswerAnimation = ({ isStopped, isPaused, showAnimation }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={`lottie-overlay ${showAnimation ? 'show' : ''}`}>
      <Lottie options={defaultOptions}
              height={200}
              width={200}
              isStopped={isStopped}
              isPaused={isPaused}/>
    </div>
  );
};

export default CorrectAnswerAnimation;