import React, { useState } from "react";
import { Container, Typography } from "@mui/material";
import GameBoard from "./GameBoard";
import GameOverDialog from "./GameOverDialog";
import "./styles.css"; // Import the CSS file

const App = () => {
  const [level, setLevel] = useState(1);
  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);

  const handleCorrectAnswer = () => {
    if (navigator.vibrate) {
      navigator.vibrate(200);
    }
    setScore(score + 1);
    setLevel(level + 1);
  };

  const handleWrongAnswer = () => {
    setLevel(level + 1);
  };

  const handleGameOver = () => {
    setIsGameOver(true);
  };

  const handleRestart = () => {
    setLevel(1);
    setScore(0);
    setIsGameOver(false);
  };

  return (
    <Container className="container" maxWidth="sm">
      <Typography variant="h5" align="center">
        Ely's Pairing Adventure
      </Typography>
      <Typography variant="h6" align="center">
        Level: {level} | Score: {score}
      </Typography>
      <GameBoard
        level={level}
        onCorrectAnswer={handleCorrectAnswer}
        onWrongAnswer={handleWrongAnswer}
        onGameOver={handleGameOver}
      />
      {isGameOver && <GameOverDialog score={score} onRestart={handleRestart} />}
    </Container>
  );
};

export default App;
