import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const GameOverDialog = ({ score, onRestart }) => {
  return (
    <Dialog open={true}>
      <DialogTitle>Game Over</DialogTitle>
      <DialogContent>
        <p>Your score: {score}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRestart} color="primary">
          Restart
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GameOverDialog;
